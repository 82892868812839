import Layout from "./common/layout"
import ClientsPartner from "./ClientsPartner/ClientsPartner"
import Head from "./common/Head"
import { useFormatter } from "helpers/i18n"

const ClientsPartnerPage = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "OnPoint top global brands"
            : "Khách hàng của OnPoint"
        }
        description={
          currentLocale === "en"
            ? "OnPoint has proven track record in providing services to brands across multiple product categories."
            : "OnPoint không ngừng mang đến giải pháp tốt nhất cho khách hàng với chuyên môn về các kênh thương mại điện tử, ngành hàng và công nghệ."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/clients-partner-meta.png"
        }
      />
      <Layout>
        <ClientsPartner />
      </Layout>
    </>
  )
}

export default ClientsPartnerPage
